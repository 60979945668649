import { Button, Dialog } from "@netfront/ui-library"
import { QualtricsDialogProps } from "./QualtricsDialog.interfaces"

export const QualtricsDialog = ({ isOpen, onClose }: QualtricsDialogProps) => {
  return(
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title='Help up improve Stable Ground - Complete a short survey!'
    >
      <p>We’d love to hear from you! The University of Sydney is conducting a brief <strong>5-10 minute online survey</strong> to learn about your experiences with the Stable Ground portal. We want to hear from everyone – even if you’ve only checked it out once.</p>

      <p>
        The purpose of the study is to make the Stable Ground portal as beneficial as possible to its visitors. Your perspectives and experiences will be used to help inform us on how to improve the portal to best support public safety personnel.
      </p>
      <p>
        All information will be kept <strong>completely confidential and anonymous</strong>.
      </p>

      <p>
        For every survey that is completed, <strong>a donation of $20</strong> will be donated to a charity of your choice.
      </p>
      <p>Find more information here: </p>

      <Button
        linkButton={{ id: '', url: 'https://sydney.au1.qualtrics.com/jfe/form/SV_5uIYsusLrWQuvf8' }}
        text='Visit survey'
      />
    </Dialog>
  )
};