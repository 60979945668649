import '@egjs/flicking-plugins/dist/arrow.min.css';
import '@egjs/flicking-plugins/dist/pagination.min.css';
import '@egjs/react-flicking/dist/flicking.css';

import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';

import 'react-circular-progressbar/dist/styles.css';

import '../styles/cms.css';
import '../styles/cms/accordion.css';
import '../styles/cms/base.css';
import '../styles/cms/calendar.css';
import '../styles/cms/checkbox.css';
import '../styles/cms/custom.css';
import '../styles/cms/print.css';
import '../styles/cms/radio.css';
import '../styles/cms/range.css';
import '../styles/cms/section.css';
import '../styles/cms/snippet.css';
import '../styles/cms/table.css';
import '../styles/components.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';

import { useEffect, useState } from 'react';

import { ContentPageProvider, GoogleAnalytics, useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useDomain, useUser } from '@netfront/gelada-identity-library';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import ErrorBoundary from '../components/Social/ErrorBoundary/ErrorBoundary';
import { SocialContextProvider } from '../contexts';
import { SessionProvider } from '../contexts/sessionProvider';
import Head from 'next/head';
import { useCookie } from '@netfront/common-library';
import { QualtricsDialog } from 'components';

function App({ Component, pageProps }: AppProps) {
  const googleAnalyticsMeasurementId = String(process.env.REACT_APP_GOOGLE_ANALYTICS);
  const isGoogleAnalyticsDebugMode = String(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE).toUpperCase() === 'Y';

  const { trackPageView, canRunAuthenticated } = useGoogleAnalytics();
  const { events } = useRouter();
  const { getUser } = useUser();
  const { isDomainReady, getDomain } = useDomain();

  const user = getUser();
  const { createCookie, getCookieValue, setCookie } = useCookie();

  const [isQualtricsDialogOpen, setIsQualtricsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (canRunAuthenticated || !user) {
      trackPageView(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRunAuthenticated]);

  useEffect(() => {
    if(!isDomainReady) return;

    const handleRouteChange = () => {
      if (canRunAuthenticated || !user) {
        trackPageView(window.location.pathname);
      }

      if(getCookieValue('agency')) {
        if(getCookieValue('shouldShowQualtrics') === 'true') {
          setIsQualtricsDialogOpen(true);

          const qualtricsSurveyCookie = createCookie('shouldShowQualtrics', 'false', {
            domain: getDomain(),
            storageExpiryOptions: {
              currentTimeValueInMilliseconds: new Date().getTime(),
              expiryTime: {
                unit: 'days',
                value: 14,
              },
            },
          });
      
          setCookie(qualtricsSurveyCookie);
        }
      }

    };

    events.on('routeChangeComplete', handleRouteChange);
  }, [isDomainReady]);

  return (
    <div id="root">
      <GoogleAnalytics
        googleAnalyticsMeasurementId={googleAnalyticsMeasurementId}
        isGoogleAnalyticsDebugMode={isGoogleAnalyticsDebugMode}
        headComponent={Head}
        user={user}
      />

      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>

      <QualtricsDialog
        isOpen={isQualtricsDialogOpen}
        onClose={() => {
          setIsQualtricsDialogOpen(false)
        }}
      />

      <SessionProvider>
        <SocialContextProvider>
          <ContentPageProvider>
            {
              // <ErrorBoundary> Commenting out to supress import error until solution
                // {/* // eslint-disable-next-line react/jsx-props-no-spreading */}
                <Component {...pageProps} />
              // </ErrorBoundary>
            }
          </ContentPageProvider>
        </SocialContextProvider>
      </SessionProvider>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
